:root {
    --bso: #FFF200;

    --white: #FFFFFF;
    --gray-100: #F6F6F6;
    --gray-200: #EDEDED;
    --gray-300: #D8D8D8;
    --gray-400: #929292;
    --gray-500: #666666;
    --dark: #060603;

    --glass-gray: #8080804D;
    --glass-white: #FFFFFF4D;

    --info: #2168F2;
    --info-light: #C9E4FF;
    --danger: #DC5454;
    --danger-light: #FFC3C3;
    --warning: #EB9948;
    --warning-light: #EAD2BA;
    --success: #3E8367;
    --success-light: #C0DED2;
}
