@import '/src/styles/functions'

.page
  //vh-100 d-flex flex-column justify-content-center px-16 bg-white
  height: 100vh
  display: flex
  flex-direction: column
  justify-content: center

  padding: 0 px-to-rem(48)
  background: var(--white)
  &__content
    // d-flex flex-column align-items-center gap-8
    display: flex
    flex-direction: column
    align-items: center
    gap: px-to-rem(24)
  &__logo
    height: px-to-rem(50)
  &__title
    // fw-bold fs-3x
    font-weight: 600
    font-size: px-to-rem(24)
    line-height: px-to-rem(32)
  &__form
    // w-100 d-flex flex-column align items-center gap-8 pt-5
    width: 100%
    display: flex
    flex-direction: column
    gap: px-to-rem(24)
    padding-top: px-to-rem(15)
  &__separatorWrapper
    //d-flex align-items-center gap-5
    display: flex
    align-items: center
    gap: px-to-rem(16)

    font-weight: 500
    font-size: px-to-rem(14)
    line-height: px-to-rem(21)
    color: var(--gray-400)
  &__textAction
    // text-center fs-5 fw-medium text-gray-400
    text-align: center
    font-size: px-to-rem(14)
    line-height: px-to-rem(21)
    color: var(--gray-400)
    span
      text-decoration: underline
      text-underline-offset: px-to-rem(3)