@import '/src/styles/functions'

.separator
  // 'h-100 w-100 d-flex align-items-center'
  height: 100%
  width: 100%
  display: flex
  align-items: center
  div
    width: 100%
    height: px-to-rem(1)
  &-gray-400
    background: var(--gray-400)
  &-gray-300
    background: var(--gray-300)
