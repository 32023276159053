@import '/src/styles/functions'

.button
  //d-flex align-items-center justify-content-center gap-1 lh-1 p-4
  display: flex
  align-items: center
  justify-content: center
  gap: px-to-rem(3)
  padding: px-to-rem(12)
  &__br8
    border-radius: px-to-rem(8)
  &__br48
    border-radius: px-to-rem(48)
  &__h34
    height: px-to-rem(34)
  &__h37
    height: px-to-rem(37)
  &__h42
    height: px-to-rem(42)
  &__h48
    height: px-to-rem(48)
  &__wFull
    // width: 100%
  &__wFit
    width: fit-content
    text-wrap: none

  &__dark
    // bg-dark text-white fw-medium
    background: var(--dark)
    color: var(--white)
    font-weight: 500
  &__white
    // bg-white border border-dark text-dark fw-medium
    background: var(--white)
    border: 1px solid var(--dark)
    color: var(--dark)
    font-weight: 500
  &__gray
    // bg-secondary text-dark fw-medium
    background: var(--gray-300)
    color: var(--dark)
    font-weight: 500
  &__bso
    // bg-bso text-dark fw-medium
    background: var(--bso)
    color: var(--dark)
    font-weight: 500
  &__small
  &__large
    font-size: px-to-rem(16)
    line-height: px-to-rem(24)
