@keyframes spinner-border
  100%
    transform: rotate(360deg)

.spinner
  display: inline-block
  width: 1rem
  height: 1rem
  vertical-align: text-bottom
  border: 0.2em solid currentColor
  border-right-color: transparent
  border-radius: 50%
  animation: spinner-border 0.75s linear infinite