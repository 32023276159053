@import '/src/styles/functions'

.textInput
  // text-input w-100 d-flex flex-column gap-3
  display: flex
  flex-direction: column
  gap: px-to-rem(8)
  &__container
    // d-flex align-items-cetner justify-content-between gap-3 ps-5 border w-100
    // width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    gap: px-to-rem(8)
    padding-left: px-to-rem(16)
    border: 1px solid var(--gray-300)
    border-radius: px-to-rem(8)
    &_white
      background: var(--white)
    &_gray
      // bg-gray-100 text-gray-500
      background: var(--gray-100)
      color: var(--gray-500)
    &_error
      border-color: var(--danger)
  &__input
    // bg-none border-0 fs-5 fw-medium w-100 lh-1 py-3
    background: none
    border: none
    outline: none
    box-shadow: none
    width: 100%
    font-size: px-to-rem(14)
    line-height: px-to-rem(21)
    padding: px-to-rem(10) 0
  &__passwordToggle
    // d-flex align-items-center fs-2x text-gray-400 py-3 pe-5
    display: flex
    align-items: center
    font-size: px-to-rem(16)
    color: var(--gray-400)
    padding: px-to-rem(10) px-to-rem(16) px-to-rem(10) 0
  &__error
    // d-flex align-items-center justify-content-between text-danger fs-5 fw-medium
    display: flex
    align-items: center
    justify-content: space-between
    color: var(--danger)
    font-size: px-to-rem(14)
    line-height: px-to-rem(21)
    font-weight: 500
    &__icon
      font-size: px-to-rem(22)